@use "sass:math";

.-pagetop-end {
  .global-Pagetop {
    @include mq(lg) {
      bottom: rem(131);
    }
  }
}

.global-Pagetop {
  @include mq(lg) {
    position: fixed;
    right: rem(40);
    bottom: rem(56);
    z-index: 200;
  }

  a {
    position: relative;
    display: block;
    height: rem(39);
    background-color: $color-gray2;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      width: rem(56);
      height: rem(56);
      border-radius: rem(50%);
      opacity: 0.7;
    }

    & > span {
      display: none;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: rem(10);
      height: rem(10);
      content: "";
      border-top: rem(2) solid $color-white;
      border-right: rem(2) solid $color-white;
      transform: translate(-25%, -25%) rotate(-45deg);

      @include mq(lg) {
        transform: translate(-50%, -40%) rotate(-45deg);
      }
    }

    @include hover-without-touch {
      @include mq(md) {
        opacity: 0.5;
      }
    }
  }
}
