.global-Show_Sp {
  display: initial !important;

  @include mq(md) {
    display: none !important;
  }
}

.global-Show_Tablet {
  display: none !important;

  @include mq(md) {
    display: initial !important;
  }

  @include mq(lg) {
    display: none !important;
  }
}

.global-Show_Pc {
  display: none !important;

  @include mq(lg) {
    display: initial !important;
  }
}
