@keyframes animation-borderwide {
  0% {
    transform: scale(0, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes animation-slidein {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes animation-slideout {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes animation-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-followup {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-48px);
  }
}

@keyframes animation-followdown {
  0% {
    transform: translateY(-48px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animation-followuphm {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-52px);
  }
}

@keyframes animation-followdownhm {
  0% {
    transform: translateY(-52px);
  }

  100% {
    transform: translateY(0);
  }
}
