@font-face {
  font-family: template-iconfont;
  font-style: normal;
  font-weight: normal;
  src: url("../font/template-iconfont.eot");
  src: url("../font/template-iconfont.eot?#iefix") format("eot"),
    url("../font/template-iconfont.woff") format("woff"),
    url("../font/template-iconfont.ttf") format("truetype"),
    url("../font/template-iconfont.svg#template-iconfont") format("svg");
}

.st-Icon-Internal::before,
.st-Icon-External::before,
.st-Icon-Pdf::before {
  display: inline-block;
  margin-top: -0.1em;
  font-family: template-iconfont, sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.st-Icon-Internal::before {
  color: inherit;
  content: "";
}

.st-Icon-External::before {
  color: inherit;
  content: "";
}

.st-Icon-Pdf::before {
  color: #df0523;
  content: "";
}

/* サンプル用スタイル */
.st-Icon-Sample a {
  color: #df0523;
  text-decoration: underline;
  transition-duration: 0.3s;
}

.st-Icon-Sample a:hover {
  text-decoration: none;
}
