@use "sass:math";

.local-Conversion-FadeSlideIn {
  @include mq(lg) {
    opacity: 0;
    animation-name: conversionmenusub;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.local-Conversion-FadeSlideOut {
  @include mq(lg) {
    opacity: 0;
    animation-name: conversionmenusubout;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

@keyframes conversionmenusub {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: rem(57);
    opacity: 1;
  }
}

@keyframes conversionmenusubout {
  from {
    right: rem(57);
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

.-show_spConversion {
  .local-Conversion {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;

    @include mq(lg) {
      bottom: auto;
      left: auto;
      display: none;
      width: auto;
      opacity: 0;
    }
  }
}

.local-Conversion {
  display: none;

  @include mq(lg) {
    position: fixed;
    inset: 50% rem(-60) auto auto;
    z-index: 113;
    display: none;
    display: block;
    width: auto;
    opacity: 0;
    transform: translate(0, -50%);
  }
}

.local-Conversion_Inner {
  @include mq(lg) {
    display: inline-block;
    background-color: $color-white;
    border-radius: rem(3) 0 0 rem(3);
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 16%);
  }
}

.local-Conversion_Menu {
  display: flex;

  @include mq(lg) {
    display: block;
  }
}

.local-Conversion_MenuItem {
  flex: 1;
  align-self: stretch;

  & + .local-Conversion_MenuItem {
    border-left: rem(1) solid $color-white;

    @include mq(lg) {
      border-left: none;
    }
  }

  @include mq(lg) {
    position: relative;

    &:first-child {
      .local-Conversion_MenuTitle {
        border-top: rem(3) solid $color-accent;
        border-top-left-radius: rem(3);
      }
    }

    &:last-child {
      .local-Conversion_MenuTitle {
        border-bottom-left-radius: rem(3);
      }
    }
  }
}

.local-Conversion_MenuTitle {
  display: block;
  height: 100%;
  padding: rem(13) rem(5);
  color: $color-white;
  text-align: center;
  text-decoration: none;
  background-color: $color-accent;

  span {
    padding: 0;
    font-size: rem(14);
    font-weight: 500;
    font-feature-settings: "palt";
    line-height: rem(18);
    text-align: center;
    letter-spacing: 0.05em;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    br {
      display: none;
    }

    svg {
      width: rem(18);
      height: rem(18);
      margin-right: rem(8);
      vertical-align: middle;
      fill: $color-white;
    }
  }

  @include mq(lg) {
    position: relative;
    z-index: 104;
    display: table;
    width: rem(58);
    height: auto;
    min-height: rem(80);
    padding: rem(0);
    color: $color-black;
    text-align: left;
    text-decoration: none;
    background-color: $color-white;
    border-top: rem(1) solid $color-gray10;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    span {
      display: table-cell;
      height: auto;
      padding: rem(14) 0;
      font-size: rem(11);
      font-weight: 600;
      font-feature-settings: "palt";
      line-height: 127%;
      text-align: center;
      letter-spacing: 0.05em;
      vertical-align: middle;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;

      br {
        display: inherit;
      }

      svg {
        width: rem(24);
        height: rem(26);
        margin-right: rem(0);
        margin-bottom: rem(6);
        fill: $color-accent;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }
    }

    @include hover-without-touch {
      color: $color-white;
      background-color: $color-accent;

      span {
        svg {
          fill: $color-white;
        }
      }
    }

    &.-is-active {
      color: $color-white;
      background-color: $color-accent;

      span {
        svg {
          fill: $color-white;
        }
      }
    }

    &.-is-height {
      span {
        padding: rem(7) 0;
      }
    }
  }
}

.local-Conversion_MenuSub {
  @include mq(lg) {
    position: absolute;
    top: 0;
    right: rem(0);
    z-index: 103;
    display: none;
    padding-right: rem(11);
    opacity: 0;
  }
}

.local-Conversion_MenuSubInner {
  @include mq(lg) {
    position: relative;
    background-color: $color-white;
    border-radius: rem(6);
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 16%);

    &::before {
      position: absolute;
      top: rem(22);
      right: rem(-5);
      display: block;
      width: rem(10);
      height: rem(10);
      content: "";
      background-color: $color-white;
      transform: rotate(45deg);
    }
  }
}

.local-Conversion_MenuSubItem {
  @include mq(lg) {
    border-top: rem(1) solid $color-gray10;

    &:first-child {
      border-top: none;
    }

    a {
      display: block;
      padding-right: rem(10);
      color: $color-black;
      text-decoration: none;
      letter-spacing: 0;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;

      @include hover-without-touch {
        color: $color-accent;
      }
    }

    span {
      display: block;
      padding: rem(16) rem(15);
      font-size: rem(13);
      font-weight: 600;
      line-height: 138%;
      letter-spacing: 0;
      white-space: nowrap;

      svg {
        width: rem(22);
        height: rem(22);
        margin-right: rem(10);
        vertical-align: middle;
        fill: $color-accent;
      }
    }
  }
}

@keyframes conversionshow {
  from {
    right: rem(-60);
    opacity: 0;
  }

  to {
    right: rem(0);
    opacity: 1;
  }
}

@keyframes conversionhide {
  from {
    right: rem(0);
    opacity: 1;
  }

  to {
    right: rem(-60);
    opacity: 0;
  }
}

.-is-ShowConversion {
  @include mq(lg) {
    opacity: 0;
    animation-name: conversionshow;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.-is-HideConversion {
  @include mq(lg) {
    opacity: 0;
    animation-name: conversionhide;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}
