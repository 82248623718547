/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */

/* Sweep To Right */
.hvr-sweep-to-right {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);

  @include hover-without-touch {
    &::before {
      transform: scale(1.2);
    }
  }
}

.hvr-sweep-to-right::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background: #fff;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

/* Sweep To Left */
.hvr-sweep-to-left {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);

  @include hover-without-touch {
    &::before {
      transform: scale(1.2);
    }
  }
}

.hvr-sweep-to-left::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background: #fff;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

/* Sweep To Bottom */
.hvr-sweep-to-bottom {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);

  @include hover-without-touch {
    &::before {
      transform: scale(1.2);
    }
  }
}

.hvr-sweep-to-bottom::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background: #fff;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
