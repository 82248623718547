@use "sass:math";

.global-Breadcrumb {
  padding-bottom: rem(12);
  margin-top: rem(12);

  @include mq(md) {
    margin-top: rem(32);
  }

  @include mq(lg) {
    margin-top: rem(48);
  }

  ol {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: calc($global-max-width + rem(56));
    padding: 0 rem(16);
    margin: 0 auto;

    @include mq(md) {
      padding: 0 rem(28);
    }

    li {
      position: relative;
      padding-right: rem(24);
      margin-top: rem(8);
      line-height: rem(11);
      list-style: none;

      &::after {
        position: absolute;
        right: rem(7);
        bottom: rem(1);
        display: block;
        width: rem(8);
        height: rem(8);
        content: "";
        background-image: $svg-arw-gray1;
        background-size: 8px 8px;
      }

      &:last-child {
        padding-right: rem(0);

        &::after {
          display: none;
        }

        a {
          pointer-events: none;
        }
      }

      a {
        font-size: rem(11);
        font-weight: 500;
        line-height: rem(11);
        color: $color-gray1;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        @include hover-without-touch {
          @include mq(md) {
            color: $color-accent;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
