@use "sass:math";

.local-PageTitle {
  border-bottom: rem(1) solid $color-border;

  @include mq(lg) {
    position: relative;
    z-index: 116;
    background-color: $color-white;
  }

  &:has(+ .local-MainNavi_Pc) {
    @include mq(lg) {
      border-bottom: none;
    }
  }
}

.local-PageTitle_Inner {
  display: flex;
  justify-content: space-between;
  max-width: calc($global-max-width + rem(56));
  padding: rem(0) rem(16);
  margin: 0 auto;

  @include mq(md) {
    padding: rem(0) rem(24);
  }

  @include mq(lg) {
    padding: rem(0) rem(28);
  }
}

.local-PageTitle_Main {
  padding: rem(10) 0;
  font-size: rem(18);
  font-weight: 500;
  line-height: rem(27);
  color: $color-black;

  @include mq(lg) {
    padding: rem(16) 0;
    font-size: rem(22);
    line-height: rem(22);
  }
}

.local-PageTitle_SubInner {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
