@use "sass:math";

.local-MainContainer {
  max-width: calc($global-max-width + rem(56));
  padding: rem(16) rem(16) 0;
  margin: 0 auto;

  @include mq(md) {
    padding: rem(28) rem(40) 0;
  }

  @include mq(lg) {
    padding: rem(28) rem(52) 0;
  }

  @include mq(xxl) {
    padding: rem(28) rem(28) 0;
  }
}
