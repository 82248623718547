@use "sass:math";

/*
main {
  background-color: lightblue;

  @include mq(md) {
    background-color: lightgreen;
  }

  @include mq(lg) {
    background-color: lightcoral;
  }

  @include mq(xl) {
    background-color: lightpink;
  }

  @include mq(global-l) {
    background-color: lightyellow;
  }
}
*/

.global-Footer {
  border-top: rem(1) solid $color-border;
}

.global-Footer_Inner {
  max-width: calc($global-max-width + rem(56));
  padding: 0 0 rem(16) 0;
  margin: 0 auto;

  @include mq(lg) {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(28);
  }
}

.global-Footer_Sns {
  margin: rem(32) 0;

  @include mq(lg) {
    margin: rem(38) 0;
  }

  ul {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0;

    @include mq(lg) {
      justify-content: flex-start;
    }

    li {
      padding: 0;
      margin: 0 rem(12);
      list-style: none;

      @include mq(lg) {
        margin: 0 rem(24) 0 0;
      }

      a {
        display: block;

        svg {
          width: rem(24);
          height: rem(24);
          vertical-align: bottom;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
        }

        @include hover-without-touch {
          @include mq(md) {
            svg {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.global-Footer_Link {
  ul {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0 rem(12);
      list-style: none;

      @include mq(lg) {
        justify-content: flex-end;
        margin: 0 0 0 rem(24);
      }

      a {
        display: block;
        font-size: rem(13);
        font-weight: 400;
        line-height: rem(19.24);
        color: $color-gray1;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        @include mq(lg) {
          font-size: rem(14);
          line-height: rem(20.72);
        }

        @include hover-without-touch {
          @include mq(md) {
            color: $color-accent;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.global-Footer_Copyright {
  margin-top: rem(24);
  font-family: $global-font-family-notosans;
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(17.76);
  color: $color-gray1;
  text-align: center;

  @include mq(lg) {
    margin-top: rem(16);
    text-align: right;
  }
}

.global-Footer_PermissionNumber {
  padding: rem(10) rem(16);
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(17.76);
  color: $color-gray1;
  text-align: center;
  background-color: $color-bg-navi;
  border-top: rem(1) solid $color-border;
}
