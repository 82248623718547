@use "sass:math";

.local-FooterSitemap_Inner {
  max-width: calc($global-max-width + rem(56));
  padding: rem(36) rem(15) 0;
  margin: 0 auto;

  @include mq(md) {
    padding: rem(40) rem(40) 0;
  }

  @include mq(lg) {
    padding: rem(40) rem(52) 0;
  }

  @include mq(xxl) {
    padding: rem(40) rem(28) 0;
  }
}

.local-FooterSitemap_TitleBox {
  margin-bottom: rem(20);

  @include mq(md) {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: rem(24);
  }

  @include mq(lg) {
    margin-bottom: rem(20);
  }
}

.local-FooterSitemap_Logo {
  @include mq(md) {
    flex-shrink: 0;
    margin-right: rem(32);
  }

  img {
    width: rem(110);

    @include mq(md) {
      width: rem(159);
    }
  }
}

.local-FooterSitemap_Title {
  margin-top: rem(0);
  font-size: rem(18);
  font-weight: 700;
  line-height: 200%;

  @include mq(lg) {
    font-size: rem(20);
  }
}

.local-FooterSitemap_MenuBox {
  margin-right: rem(-15);
  margin-left: rem(-15);
  border-top: 1px solid $color-border-ui;

  @include mq(md) {
    margin-right: rem(0);
    margin-left: rem(0);
  }

  @include mq(lg) {
    border-top: none;
  }
}

.local-FooterSitemap_MenuBoxInner {
  @include mq(lg) {
    display: flex;
    flex-flow: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: rem(-32);
  }

  &.-is-col3 {
    .local-FooterSitemap_MenuBoxSub {
      @include mq(lg) {
        flex-basis: calc(math.percentage(math.div(4, 12)) - rem(32));
        max-width: calc(math.percentage(math.div(4, 12)) - rem(32));
      }
    }
  }
}

.local-FooterSitemap_MenuBoxSub {
  @include mq(lg) {
    flex-basis: calc(math.percentage(math.div(3, 12)) - rem(32));
    align-self: stretch;
    max-width: calc(math.percentage(math.div(3, 12)) - rem(32));
    margin-left: rem(32);
  }

  & > *:first-child {
    margin-top: rem(0);
  }
}

.local-FooterSitemap_Menu {
  @include mq(lg) {
    margin-top: rem(20);
  }

  &.-is-open {
    .local-FooterSitemap_MenuTitle {
      background-color: $color-bg-navi;

      @include mq(lg) {
        background-color: $color-white;
      }

      a {
        &::after {
          transform: translate(0, -50%) rotate(-90deg);

          @include mq(lg) {
            transform: translate(0, -50%) rotate(0deg);
          }
        }
      }
    }
  }
}

.local-FooterSitemap_MenuTitle {
  border-bottom: 1px solid $color-border-ui;

  &.-is-accordion {
    a {
      &::after {
        display: initial;
        transform: translate(0, -50%) rotate(90deg);

        @include mq(lg) {
          transform: translate(0, -50%) rotate(0deg);
        }
      }
    }
  }

  &.-is-linknone {
    a {
      @include mq(lg) {
        pointer-events: none;
      }

      &::after {
        @include mq(lg) {
          display: none;
        }
      }
    }
  }

  a {
    position: relative;
    display: block;
    padding: rem(15) rem(30) rem(15) rem(15);
    font-size: rem(14);
    font-weight: 700;
    line-height: 150%;
    color: $color-black;
    text-decoration: none;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      padding: rem(12) rem(12) rem(12) rem(0);
    }

    &::after {
      position: absolute;
      top: 50%;
      right: rem(20);
      z-index: 2;
      display: block;
      display: none;
      width: rem(10);
      height: rem(10);
      content: "";
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7 10" xml:space="preserve"><path fill="%23df0522" d="M0.8,8.8L4.6,5L0.8,1.2L2,0l5,5l-5,5L0.8,8.8z"/></svg>');
      background-size: 10px 10px;
      transform: translate(0, -50%) rotate(0deg);

      @include mq(lg) {
        right: rem(0);
        display: initial;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7 10" xml:space="preserve"><path fill="%23999999" d="M0.8,8.8L4.6,5L0.8,1.2L2,0l5,5l-5,5L0.8,8.8z"/></svg>');
        background-size: 10px 10px;
      }
    }

    @include hover-without-touch {
      color: $color-accent;

      &::after {
        @include mq(lg) {
          background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7 10" xml:space="preserve"><path fill="%23df0522" d="M0.8,8.8L4.6,5L0.8,1.2L2,0l5,5l-5,5L0.8,8.8z"/></svg>');
          background-size: 10px 10px;
        }
      }
    }
  }
}

.local-FooterSitemap_MenuList {
  display: none;
  padding: rem(16) rem(20);
  background-color: $color-bg-ui;
  border-bottom: 1px solid $color-border-ui;

  @include mq(lg) {
    display: block !important;
    padding: rem(5) 0 0;
    background-color: $color-white;
    border-bottom: none;
  }
}

.local-FooterSitemap_MenuListItem {
  &.-is-splink {
    a {
      font-weight: 700;
    }

    @include mq(lg) {
      display: none;
    }
  }

  &.-is-level-2 {
    padding-left: rem(18);

    a {
      font-size: rem(12);
    }
  }

  a {
    display: block;
    padding: rem(8) 0;
    font-size: rem(12);
    font-weight: 500;
    line-height: 160%;
    color: $color-black;
    text-decoration: none;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      padding: rem(8) 0 0;
      font-size: rem(13);
    }

    @include hover-without-touch {
      color: $color-accent;
    }

    svg {
      width: rem(11);
      height: rem(11);
      margin-left: rem(3);
      vertical-align: initial;
      fill: $color-accent;
    }
  }
}

.local-FooterSitemap_MenuListSub {
  margin-top: rem(20);
}

.local-FooterSitemap_MenuListSubTitle {
  position: relative;
  padding-left: rem(18);
  margin-top: rem(6);
  font-size: rem(13);
  font-weight: 700;
  line-height: 150%;

  @include mq(lg) {
    margin-top: rem(11);
  }

  &::before {
    position: absolute;
    top: rem(8);
    left: 0;
    display: block;
    width: rem(12);
    height: rem(3);
    content: "";
    background-color: $color-black;
  }
}

.local-FooterSitemap_MenuListJson {
  &.-is-subtitle {
    padding-left: rem(18);

    a {
      font-size: rem(12);
    }
  }
}
