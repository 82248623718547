@use "sass:math";

.local-TextLinkSp {
  padding: rem(10) rem(10) rem(16);
  background-color: $color-gray5;

  @include mq(lg) {
    display: none;
  }
}

.local-TextLinkSpInner {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0 rem(8);
      list-style: none;

      a {
        padding-left: rem(12);
        font-size: rem(12);
        font-weight: 500;
        line-height: rem(18);
        color: $color-white;
        text-align: left;
        text-decoration: none;
        white-space: nowrap;
        background-image: $svg-arw-accent;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 10px 10px;
      }
    }
  }
}

.local-TextLinkPc {
  display: none;

  @include mq(lg) {
    display: block;
    margin-top: rem(5);
    margin-left: rem(14);
  }
}

.local-TextLinkPcInner {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0 0 0 rem(16);
      list-style: none;

      a {
        padding-left: rem(12);
        font-size: rem(12);
        font-weight: 500;
        line-height: rem(18);
        color: $color-black;
        text-align: left;
        text-decoration: none;
        white-space: nowrap;
        background-image: $svg-arw-accent;
        background-repeat: no-repeat;
        background-position: left top 2px;
        background-size: 10px 10px;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        @include hover-without-touch {
          color: $color-accent;
        }
      }
    }
  }
}
